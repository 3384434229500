import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import {
  Breadcrumb,
  Button,
  Container,
  Form,
  Modal,
  Overlay,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { dateDisplay, getFormattedDate } from "../helpers/functions";
import TableToExcel from "../helpers/Excel";

function Confirmation() {
  const search = useRef(null);
  const [display, setDisplay] = useState([]);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    axios
      .post("api/teacherdata")
      .then((res) => {
        setData(res.data.rows);
        setDisplay(res.data.rows);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleChange = (placement, val, id) => {
    const data = { val, id, placement };
    console.log(data);
    axios
      .post("api/updateteacherdata", data)
      .then((res) => {
        setData(res.data.rows);
        setDisplay(res.data.rows);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleConfirm = () => {
    setShow(false);
    axios
      .post("api/updateteacherdatafinished")
      .then((res) => {
        if (res.data.ok === "updated"){
          alert("המידע נשמר בהצלחה");
        }
        
       })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Container className="text-center">
        <h3 className="pt-2">נתוני מורים</h3>
        <hr />
        <Row className="mb-1">
          <Col xs={0} md={0} lg={0} xl={0} xxl={0} className="p-0"></Col>
          <Col xs={12} md={4} lg={3} xl={2} xxl={2}>
            <Button variant="primary" onClick={handleShow}>
              אישור סיום הזנה
            </Button>
          </Col>
          <Col xs={12} md={4} lg={3} xl={2} xxl={2}>
            <Form.Control
              ref={search}
              type="text"
              placeholder="חיפוש"
              onChange={(e) =>
                setDisplay(
                  data.filter((i) =>
                    `${i.first_name} ${i.last_name}${i.phone}${i.phone2}${i.mail}`.includes(
                      e.target.value
                    )
                  )
                )
              }
            />
          </Col>
        </Row>
        <Table striped bordered hover className="border-3 border-dark">
          {/*<thead className="table-sticky1">
            <tr>
              <th colSpan={2}></th>
              <th colSpan={2}>מתמטיקה</th>
              <th colSpan={2}>מדע וטכנולוגיה</th>
              <th colSpan={2}>אנגלית </th>
            </tr>
          </thead>*/}
          <thead className="table-sticky2">
            <tr>
              <th># עובד</th>
              <th>שם</th>
              <th>טלפון</th>
              <th>מייל</th>
              {/*<th>התמחות בתחום</th>
              <th>מלמד בפועל</th>
              <th>התמחות בתחום</th>
              <th>מלמד בפועל</th>
              <th>התמחות בתחום</th>
              <th>מלמד בפועל</th>*/}
            </tr>
          </thead>
          <tbody>
            {display.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.identifier}</td>
                  <td className="text-end">
                    {row.first_name} {row.last_name}
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={row.phone}
                        onBlur={(e) => handleChange(1, e.target.value, row.id)}
                        placeholder="הכנס טלפון"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group d-flex justify-content-center gap-3">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={row.mail}
                        onBlur={(e) => handleChange(2, e.target.value, row.id)}
                        placeholder="הכנס מייל"
                      />
                    </div>
                  </td>

                  {/*<td>
                    <div className="d-flex justify-content-center gap-3">
                      <button
                        className={`btn ${row.math_cert === 1
                            ? "btn-success"
                            : "btn-outline-success"
                          }`}
                        onClick={() => handleChange(3, 1, row.id)}
                      >
                        <span
                          className={row.math_cert === 1 ? "text-white" : ""}
                        >
                          כן
                        </span>
                      </button>
                      <button
                        className={`btn ${row.math_cert !== 1
                            ? "btn-danger"
                            : "btn-outline-danger"
                          }`}
                        onClick={() => handleChange(3, 0, row.id)}
                      >
                        <span
                          className={row.math_cert !== 1 ? "text-white" : ""}
                        >
                          לא
                        </span>
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <button
                        className={`btn ${row.math_teach === 1
                            ? "btn-success"
                            : "btn-outline-success"
                          }`}
                        onClick={() => handleChange(4, 1, row.id)}
                      >
                        <span
                          className={row.math_teach === 1 ? "text-white" : ""}
                        >
                          כן
                        </span>
                      </button>
                      <button
                        className={`btn ${row.math_teach !== 1
                            ? "btn-danger"
                            : "btn-outline-danger"
                          }`}
                        onClick={() => handleChange(4, 0, row.id)}
                      >
                        <span
                          className={row.math_teach !== 1 ? "text-white" : ""}
                        >
                          לא
                        </span>
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <button
                        className={`btn ${row.tech_cert === 1
                            ? "btn-success"
                            : "btn-outline-success"
                          }`}
                        onClick={() => handleChange(5, 1, row.id)}
                      >
                        <span
                          className={row.tech_cert === 1 ? "text-white" : ""}
                        >
                          כן
                        </span>
                      </button>
                      <button
                        className={`btn ${row.tech_cert !== 1
                            ? "btn-danger"
                            : "btn-outline-danger"
                          }`}
                        onClick={() => handleChange(5, 0, row.id)}
                      >
                        <span
                          className={row.tech_cert !== 1 ? "text-white" : ""}
                        >
                          לא
                        </span>
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <button
                        className={`btn ${row.tech_teach === 1
                            ? "btn-success"
                            : "btn-outline-success"
                          }`}
                        onClick={() => handleChange(6, 1, row.id)}
                      >
                        <span
                          className={row.tech_teach === 1 ? "text-white" : ""}
                        >
                          כן
                        </span>
                      </button>
                      <button
                        className={`btn ${row.tech_teach !== 1
                            ? "btn-danger"
                            : "btn-outline-danger"
                          }`}
                        onClick={() => handleChange(6, 0, row.id)}
                      >
                        <span
                          className={row.tech_teach !== 1 ? "text-white" : ""}
                        >
                          לא
                        </span>
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <button
                        className={`btn ${row.english_cert === 1
                            ? "btn-success"
                            : "btn-outline-success"
                          }`}
                        onClick={() => handleChange(7, 1, row.id)}
                      >
                        <span
                          className={row.english_cert === 1 ? "text-white" : ""}
                        >
                          כן
                        </span>
                      </button>
                      <button
                        className={`btn ${row.english_cert !== 1
                            ? "btn-danger"
                            : "btn-outline-danger"
                          }`}
                        onClick={() => handleChange(7, 0, row.id)}
                      >
                        <span
                          className={row.english_cert !== 1 ? "text-white" : ""}
                        >
                          לא
                        </span>
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <button
                        className={`btn ${row.english_teach === 1
                            ? "btn-success"
                            : "btn-outline-success"
                          }`}
                        onClick={() => handleChange(8, 1, row.id)}
                      >
                        <span
                          className={
                            row.english_teach === 1 ? "text-white" : ""
                          }
                        >
                          כן
                        </span>
                      </button>
                      <button
                        className={`btn ${row.english_teach !== 1
                            ? "btn-danger"
                            : "btn-outline-danger"
                          }`}
                        onClick={() => handleChange(8, 0, row.id)}
                      >
                        <span
                          className={
                            row.english_teach !== 1 ? "text-white" : ""
                          }
                        >
                          לא
                        </span>
                      </button>
                    </div>
                  </td>*/}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          size="sm" // Makes the modal smaller
          centered // Centers the modal vertically and horizontally
          dir="rtl"
        >
          <Modal.Header>
            <Modal.Title>מעיין החינוך התורני</Modal.Title>
          </Modal.Header>
          <Modal.Body>בטוח שסיימת לעבור על כלל המורים במוסד?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              ביטול
            </Button>
            <Button variant="danger" onClick={handleConfirm}>
              אישור
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}
export default Confirmation;
